<script setup lang="ts">import { ref as _ref } from 'vue';

import { NuxtImg } from '#components'

definePageMeta({ title: '烟花绽放 浴火新生' })

const video = _ref<HTMLVideoElement>()

// fallback for browsers that don't support autoplay
// e.g. Safari on iOS when power saving mode is on
let interval: NodeJS.Timeout

onActivated(() => {
  if (!video.value?.paused)
    return

  interval = setInterval(() => {
    if (!navigator.userActivation?.hasBeenActive)
      return

    try {
      video.value.play()
    }
    catch {}

    clearInterval(interval)
  }, 100)
})

onDeactivated(() => clearInterval(interval))

const services = [
  {
    icon: 'clarity:license-line',
    title: '企业',
    description: '规范化的公司，优化到手收益。',
  },
  {
    icon: 'heroicons:wrench-screwdriver',
    title: '运营',
    description: '个性化运营指导，日常直播活动策划，粉丝群管理帮助，周边、舰长礼物策划。',
  },
  {
    icon: 'streamline:desktop-help',
    title: '技术',
    description: '直播相关软件调试支持，Live2D面捕设备支持，千元级调音服务，社团网站，正式社员页面设计',
  },
  {
    icon: 'ri:funds-line',
    title: '补贴',
    description: '活动回人气扶持，正式社员约稿补贴',
  },
]

const faq = [
  {
    label: '关于鲸宝在公司运营方面的决策蓝图',
    content:
      `${''
      }我们有专业的人士负责公司运营，也有靠谱可信的合作方来做财务、法务等工作。${''
      }社长坚信：专业的事要交给专业的人做。${''
      }所以观众姥爷们请不用因为觉得社长看起来是笨蛋而担心公司会突然倒闭，不会的！！！${''
      }`,
  },
  {
    label: '关于立绘形象提供方面',
    content:
      `${''
      }这个问题社长也纠结过很久，但在最终还是决定采用不提供立绘形象的方式。${''
      }因为一旦提供立绘形象，出于公司角度，不可避免的需要对主播有各种各样的要求，而且主播的到手收益会降低。${''
      }这和我们的社团理念不符，我们希望主播们都能根据自己的节奏来发展，社团不想给大家带来额外的压力，${''
      }也希望主播们能有更多的到手收益，能自由的安排这笔收入。${''
      }但不提供立绘形象并不代表我们完全不管，会在尊重主播的个人意愿上提供帮助。${''
      }(着急出道的话！我们这里还有几个现成的立绘啊！${''
      }`,
  },
  {
    label: '关于商单资源拓展',
    content:
      `${''
      }在主播拥有一定体量后，我们会为主播拓展商务合作发展空间，如多种类的商单、品牌代言机会、歌曲投稿等。${''
      }具体哪种类型的商单，甚至哪种品类的商品，就需要根据主播的发展路线和运营老师沟通了。${''
      }我们希望可以为主播增加变现渠道，让主播的发展道路拥有更多可能性，越走越稳~${''
      }`,
  },
]

const testimonials = [
  {
    quote:
      `${''
      }非常非常喜欢Hanabi_Live，在这里每一个小小想法都可以得到小伙伴们的回应，肯定或者建议对我来说都非常珍贵！${''
      }（深夜聊天总是能勾起对同事的食欲（bushi${''
      }`,
    author: {
      name: '橘莱米',
      description: 'Hanabi_Live一期生',
      to: '/member/rayme',
      avatar: {
        as: NuxtImg,
        src: 'b0349e20561fa39823ac8fedceee9c1b.png',
        width: 80,
      },
    },
  },
  {
    quote: '希望大家都可以健健康康平平安安，做好朋友一辈子，这样就能80你们久一点咯！',
    author: {
      name: '池乐还是有点饿',
      description: 'Hanabi_Live一期生',
      to: '/member/chile',
      avatar: {
        as: NuxtImg,
        src: '9d7ce2f80e1113488ed850fa65752e76.png',
        width: 80,
      },
    },
  },
  {
    quote:
      `${''
      }自从进了Hanabi_Live，再也不愁没人一起聊天一起打瓦了。${''
      }在迷茫的时候也会有人一起讨论方向，是很温暖的小家庭。${''
      }希望能够早日发展成温暖大家庭嘎！${''
      }`,
    author: {
      name: '夕雁Yukari',
      description: 'Hanabi_Live成员',
      to: '/member/yukari',
      avatar: {
        as: NuxtImg,
        src: '3bd5a5a847a2b782367d789f7ee22eaf.png',
        width: 80,
      },
    },
  },
  {
    quote:
      `${''
      }特大消息，特大消息！${''
      }自从进入Hanabi_live以来，我腰不酸了，腿不疼了，作息健康了，睡不着的时候甚至还有无可匹敌的温柔系福利社长哄睡服务！${''
      }聊天频道时刻充斥着各类宝藏，不开心的时候主动沟通共同成长！${''
      }只要你自己努力，多方位关心更适合虚拟宝宝成长方式！\n${''
      }PS：想被骂着成长欢迎寻找敖雾AOU，惹毛了的时候脾气臭的冲出宇宙x${''
      }`,
    author: {
      name: '敖雾AOU',
      description: 'Hanabi_Live成员',
      to: '/member/aou',
      avatar: {
        as: NuxtImg,
        src: 'e996175943446c31f430bea472006879.png',
        width: 80,
      },
    },
  },
  {
    quote:
      `${''
      }真的很喜欢在Hanabi和大家一起工作！${''
      }虽然日表催不动周表更别想（bushi），但是大家都是很好很好的人，有的时候晚上一起聊天哪怕只是静静地听着也很开心，感觉就像家一样~${''
      }`,
    author: {
      name: '某不知名的Staff星棠',
      description: 'Hanabi_Live Staff',
      to: '/member/starry',
      avatar: {
        as: NuxtImg,
        src: 'd9b3f76de7297233006cc724acf3d81d.png',
        width: 80,
      },
    },
  },
]
</script>

<template>
  <div>
    <!-- @vue-expect-error Vue can't normally set muted attribute -->
    <video
      ref="video"
      class="
        absolute top-0 w-full h-screen object-cover
        blur-[1px] brightness-75 dark:brightness-50
      "
      :muted.attr="''"
      autoplay
      loop
      playsinline
    >
      <source
        src="https://static.hanbi.live/assets/2087b3a40c676df1e52ceb33a9bc37ff.mp4"
        media="(orientation: portrait)"
      >
      <source
        src="https://static.hanbi.live/assets/accfc1d9dd45436a7cdc807fcd83dca7.mp4"
      >
    </video>

    <ULandingHero
      class="
        dark absolute top-0 bottom-0 m-auto w-full
        flex justify-center items-center opacity-75
      "
      :ui="{ container: 'w-full', title: 'relative max-w-screen-sm mx-auto' }"
    >
      <template #headline>
        <UButton
          color="primary"
          variant="outline"
          to="/dashboard/order"
          label="礼物系统上线啦！"
          trailing-icon="heroicons:arrow-right"
          size="xs"
          class="rounded-full font-bold"
        />
      </template>

      <template #title>
        <NuxtImg
          class="w-[85.5%] right-[-18%] relative animate-pulse"
          src="dbdaa2f8f9897e793ca6c7bb49575a99.png"
        />

        <NuxtImg
          class="absolute top-0 bottom-0 w-full m-auto"
          src="1441947b49b2e92d64c51acceeaf993b.png"
        />
      </template>

      <template #description>
        <p class="font-bold text-3xl sm:text-4xl">
          烟花绽放 浴火新生
        </p>
      </template>

      <template #bottom>
        <Icon
          class="absolute bottom-0 mx-auto text-white animate-bounce"
          name="material-symbols:keyboard-arrow-down-rounded"
          size="64"
        />
      </template>
    </ULandingHero>

    <div class="h-[calc(100vh-var(--header-height))]" />

    <ULandingHero
      title="礼物系统上线啦！"
      description="礼物系统即日起正式上线，赶快去领取你的大航海礼物吧！"
      orientation="horizontal"
      :links="[{
        color: 'primary',
        to: '/dashboard/order',
        label: '点击前往',
        trailingIcon: 'heroicons:arrow-right',
        size: 'lg',
      }]"
      :ui="{ base: 'order-last flex flex-col flex-wrap content-center' }"
    >
      <NuxtImg
        class="mx-auto max-h-[calc(100vh-var(--header-height))] rounded-lg"
        src="https://i0.hdslb.com/bfs/article/a3851eef0da96cf61ee11c2bd8b300353546602868312291.png"
        referrerpolicy="same-origin"
      />
    </ULandingHero>

    <ULandingSection id="services" title="我们为您提供">
      <ULandingGrid>
        <ULandingCard
          v-for="service, index in services"
          :key="index"
          v-bind="service"
          class="col-span-6"
          :class="[0, 3].includes(index) ? 'row-span-2' : 'row-span-4'"
        />
      </ULandingGrid>
    </ULandingSection>

    <ULandingSection
      id="partners"
      title="我们的合作伙伴"
      description="优质的美术资源，丰富的视频资源，个性装扮、收藏集上架服务，专属的出道曲，大型活动参与机会，优质的虚拟社群制作资源……"
    >
      <ULandingLogos align="center" :ui="{ images: 'justify-around' }">
        <UButton
          class="h-40"
          color="gray"
          variant="ghost"
          size="xl"
          to="/blog/recruitment#八木百货"
        >
          <NuxtImg
            class="h-full dark:invert"
            src="7ffc5856f9ad8932054ea1ce5719c347.png"
          />
        </UButton>

        <UButton
          class="h-40"
          color="gray"
          variant="ghost"
          size="xl"
          to="/blog/recruitment#eps企划组"
        >
          <NuxtImg class="h-full" src="1890477882296e00b5fa22492f8a3409.png" />
        </UButton>
      </ULandingLogos>
    </ULandingSection>

    <ULandingSection id="testimonials" title="社员买家秀">
      <UPageColumns
        class="xl:columns-4"
      >
        <div
          v-for="testimonial, index in testimonials"
          :key="index"
          class="break-inside-avoid"
        >
          <ULandingTestimonial v-bind="testimonial" />
        </div>
      </UPageColumns>
    </ULandingSection>

    <ULandingSection id="faq" title="社团问答 Q&A">
      <ULandingFAQ
        class="max-w-4xl mx-auto"
        :ui="{
          button: { label: 'font-semibold', trailingIcon: { base: 'size-6' } },
        }"
        :items="faq"
        multiple
      />
    </ULandingSection>
  </div>
</template>
